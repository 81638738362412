import HOSTED from './hosted.jpg'
import ICONS from './icons.png'
import MOBILE_1 from './mobile_1.jpg'
import MOBILE_2 from './mobile_2.jpg'
import MOBILE_3 from './mobile_3.jpg'
import MOBILE_4 from './mobile_4.jpg'

const ALL = {
    HOSTED,
    ICONS,
    MOBILE_1,
    MOBILE_2,
    MOBILE_3,
    MOBILE_4,
}

export default ALL
